.nav-item {
  cursor: pointer;
}
.table-style table {
  border: 2px solid #000000;
  border-collapse: collapse;
  width: 100%;
  margin: 0 0 -2px;
 
 
}
.table-style  {
  
   
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 5px;

 
}
.table-style thead {
  border: 2px solid #000000;
  background: #f1f2f3;
}
.table-style thead th {
  font-size: 20px;
}
.table-style td,
.table-style th {
  border: 1px solid #f1f2f3;
  padding: 10px;
  vertical-align: top;

  
}
.table-style h5 {
  font-size: 16px;
  margin: 0;
}
.table-style .inline-box {
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 20px;
}
.table-style .inline-box select {
  width: 100%;
}
.table-style .profile-img {
  vertical-align: top;
}
.table-style .profile-img img {
  max-width: 150px;
  max-height: 150px;
}


.ui.pagination.menu {
    margin-bottom: 1rem;
    display: inline-flex;
    vertical-align: middle;
    position:inherit;
    float:right;
  



}
.ui.menu .item {

    color: #4e73df;

}
.ui.pagination.menu .active.item {
    border-top: none;
    padding-top: .92857143em;
    background-color:#4e73df;
    color:#fff;;
    box-shadow: none;
}
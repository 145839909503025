body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
